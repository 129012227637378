.home-header-left-wrap{
    height: calc(90vh - 80px);
}

.home-header-wrap{
    height: calc(100vh);
    width: 100vw;
    display: flex;
    overflow: hidden;
}

.hidden{
    opacity: 0;
    transition: all 0.5s;
}

.show{
    opacity: 1;
}

.top-panel-pusher{
    height: 100vh;
    width: 100vw;
    background-color: #6284FF;
    z-index: -100;
}

.home-about-text{
    margin-top: 3em;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
    font-size: 36px;
    transform: translate(0, -2em);
}

.home-second-panel{
    margin-top: calc(100vh - 80px);
    height: 100vh;
    padding-top: 2em;
    background-color: white   ;
}

.home-intro-wrap{
    position: absolute;
    height: calc(60vh - 80px);
    width: 100vw;
    top:0;
    left:0;
    z-index: -1
}

.particle-wrapper{
    position: absolute;
    z-index: 0;
    height: calc(100vh - 80px);
    width: 100vw;
}

.home-opener{
    position: absolute;
    left: 0;
    top: 80px;
    width: 100vw;
    height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-logo{
    width: min(1024px, 70vw);
    transform: translate(0,-3em);
}

.top-home-wrap{
    position: absolute;
    left: 0;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

.right-canvas{
    width: 40vw;
    position: absolute;
    padding-left: 4em;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.down-icon-wrap{
    position: absolute;
    z-index: 100000;
    bottom: 1em;
    width: 100vw !important;
    align-items: end;
    display: none;
}

.down-icon{
    font-size: 42px !important;
    transition: all 0.5s !important;
    margin-left: calc(100vw - 1em - 42px) !important;
}

.down-icon:hover{
    transition: all 0.5s !important;
    font-size: 60px !important;
}

.right-description{
    font-size: 24px;
    padding-top: 1em;
    color: rgba(0,0,0, 0.75);
}

.right-title{
    font-size: 64px;
    line-height: 1;
}


.home-header-right-wrap{
    height: calc(100vh);
}

.home-desc-left{
    width: 50vw;
    padding-top: 120px;
}

.logo-wrapper{
    height: calc(100vh);
    width: 50vw;
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
    padding-top: 80px;
}

.prompt-btn{
    color: white;
    background-color: black;
    font-size: 32px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 1em;
    cursor: pointer;
    transition: all 0.5s;
}

.solutions-wrap{
    display: inline;
}

.prompt-btn:hover{
    transform: scale(1.1);
}

.home-desc-wrap{
    display: flex;      
    height: 100vh;
    width: 100vw;
}

.home-desc-wrap-right{
    width: 100vw;
}

.home-box{
    width: 300px;
    height: 150px;
    border-radius: 5px;
    border: 2px solid black;
    margin-top: 1em;
}

.home-box-wrap-wrap{
    width: 150px
}

.down-wrap{
    margin: auto !important;
}

.down-btn-wrap{
    z-index: 1000 !important;
    position: absolute !important;
    bottom: 2em !important;
    width: 100vw;
    margin: auto;
    display: flex;
    align-items: center;
}

.down-btn{
    color: black !important;
    transform: scale(2);
    transition: all 0.5s;
    margin: auto;
}

.down-btn:hover{
    transition: all 0.3s;
    transform: scale(2.25);
}

.logo-text{
    text-align: center;
    padding-top: 30px;
    color: white;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
    font-size: 90px;
    text-transform: uppercase;
}

.grid-wrap{
    padding-top: 2em;
}

.buzzword-wrap{
    margin-top: 4px;
    width: 100%;
    height: 100;
    border-radius: 0 0 25px 0;
    text-align: center;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
    font-size: 64px;
    color: white;
}

.home-pan2-title{
    text-align: left;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
    font-size: 64px;
    margin: 0 !important;
}

.pan2-about-wrap{
    display: flex;
    justify-content: center;
    height: 500px;
    width: 600px;
    margin: auto;
}

.pan2-desc-wrap{
    padding: 1em;
    margin: auto;
    text-align: center;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
}

.line{
    background: black;
    height: 1px;
    width: 80vw;
    margin-right: 0;
    margin-top: -3em;
}

.rainbow{
    position: absolute;
	width: 45vw;
	height: 20px;
    margin-top: 8px;
	background-repeat:repeat-x;
	background: linear-gradient(to right, 
        #eb5252, 
        #f78f2f,
        #f4c151,
        #75d850, 
        #6284FF, 
        #eb5252
 	);
	animation: rainbow 20s linear infinite;
	background-size: 200% 100% !important;
    border-radius: 0 10px 10px 0;
}
@keyframes rainbow{
	0% {background-position: 0;}
	100% {background-position: 800% 0;}
}
.color_banner{
    height: 50%;
    display: flex;
}

.slide_left{
    animation: right-to-left .5s ease-in forwards;
    margin-left: auto; 
    margin-right: 0;
}

.slide_right{
    animation: left-to-right .5s ease-in forwards;
}

@keyframes left-to-right{
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}

@keyframes right-to-left{
    0%{
        width: 0;
    }
    100%{
        width: 100%;
    }
}

@keyframes fade_in {
    0%{
        font-size: min(10vh,10vw);
        opacity: 0;
    }
    100%{
        font-size: min(10vh,10vw);
        opacity: 1;
    }
}

@keyframes fade_in1 {
    0%{
        visibility: visible;
        opacity: 0;
    }
    100%{
        visibility: visible;
        opacity: 1;
    }
}


.home_logo{
    width: 100%;
    height: 50vh;
}

.logo_text {
    align-self: flex-end;
    font-size: min(10vh,10vw);
    font-weight: bold;
    text-decoration: none;
    opacity: 0;
    color: white; 
    text-shadow: 6px 4px 5px rgba(59,59,59,0.67);
    display:block;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
}

.top_text{
    padding-left: 5vw;
    font-size: 0;
    animation: fade_in .5s .6s ease-in forwards;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
}

.bottom_text{
    animation: fade_in .5s 1.1s ease-in forwards;
    margin-left: auto; 
    margin-right: 0;
    padding-right: 5vw;
}

.home_body{
    animation: fade_in1 .5s 1.5s ease-in forwards;
    visibility: hidden;
}

.home_image{
    max-width: 95%;
    border-radius: 10px;

}

.home_grid_container{
    padding-top: 3em;
}

.overview_text {
    font-size: 20px;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
}

.overview_wrapper{
    display: flex;
    align-items: center;
}

.how_to{
    max-width: 60%;
    margin: auto;
    text-align: center;
    padding: 1em 2em 1em 2em;
}

.strong-wrap{
    transform: translateX(-2em);
}

.li_text{
    font-size: 50px !important;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
}

.home_about{
    padding: 2em 2em 2em 2em;
    text-align: center;
    max-width: 80%;
}

.home_list{
    width: 25%;
    margin: auto;
    font-family: 'Kanit', sans-serif;
    font-family: 'Roboto Slab', serif;
    color: #565656;
}

.center{
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 800px){
    .how_to{
        max-width: 90%;
        margin: 2em 1em 2em 1em;
    }
    
    .home_list{
        width: 75%;

    }

    .logo_text {
        align-self: flex-end;
        font-weight: bold;
        text-decoration: none;
        opacity: 0;
        color: white; 
        text-shadow: 6px 4px 5px rgba(59,59,59,0.67);
        display:block;
        margin: auto;
        padding: 0;
    }

    .home_logo{
        width: 100%;
        height: 40vh;
    }

    .home_about{
        padding: 0;
        margin-left: 5vw;
        margin-right: 5vw;
        padding: 1em 1em 1em 1em;
    }

    .home_image{
        max-width: 100%;
        border-radius: 0;
    }

    .home_grid_container{
        padding: 0;
    }

    .home-about-text{
        font-size: 24px;
    }
}


@media only screen and (max-width: 1024px) {
    .home-header-wrap{
        display: block;
    }

    .right-canvas{
        width: 100vw;
    }
    .logo-wrapper{
        height: auto;
        width: auto;
        padding: 0;
        height: min(700px, calc(100vh));
        overflow: hidden;
    }
    .logo{
        height: max(500px, 30vw);
        width: auto;
        margin-top: max(3em, 18vh);
        padding-top: 0.5em;
    }
    .logo-text{
        font-size: max(44px, 8vw);
        padding: 0.5em;
        padding-top: 0;
    }
    .rainbow{
        margin-top: -22px;
        width: max(95vw, 400px);
    }
    .right-canvas{
        margin-top: 0;
        backdrop-filter: blur(10px);
    }
    .right-title{
        font-size: 40px;
        padding-bottom: 0.5em;
    }
    .down-icon{
        display: block;
        filter: invert(1);
    }
    .down-icon-wrap{
        display: block;
    }
    .home-header-right-wrap{
        height: auto;
        padding-bottom: 3em;
        padding-top: 2em;
    }

    .prompt-btn{
        width: 80vw;
        font-size: 28px;
    }
    .right-description{
        font-size: 20px;
        padding-top: 0;
    }
    .right-canvas{
        padding: 1em;
        padding-top: 2em;
        margin: 0;
        backdrop-filter: none;
    }
    .home-header-right-wrap{
        width: 100vw;
        text-align: center;
    }
    .home-logo{
        width: 300px;
        transform: translate(0,-5em);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .hidden {
      transition: all 1s;
    }
  }