@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@900&family=Roboto+Slab&display=swap');

.main_nav_wrapper{
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background: rgba(255, 122, 89, 0);
	align-items: center;
    backdrop-filter: blur(5px);
}

.call-to-action{
    position: absolute;
    right: 2em;
    border: 1px solid #404040;
    font-family: 'Roboto Slab', serif;
    color: #404040;
    width: 120px;
    padding: 0.5em;
    text-align: center;
    border-radius: 30px;
    transition: all 0.5s;
    cursor:pointer;
    z-index: 10;
}

.call-to-action:hover{
    transform: scale(1.2);
}

.nav_link{
    text-decoration: none;
    color: #404040;
    transition: all 0.5s;
    font-family: 'Roboto Slab', serif;
    margin: 0 1rem;
    font-size: 24px;
}

.nav_link:hover{
    transform: scale(1.3);
}

.nav_btn{
    margin-top: 12px !important;
    cursor: pointer;
    background: transparent;
    border:none;
    outline: none;
    color: black !important;
    visibility: hidden;
    opacity: 0;
    display: none !important;
}

.main_nav_wrapper nav{
	display: flex;
	align-items: center;
}

.nav-logo{
    max-width: 250px;
    max-height: 70px;
    padding-top: 8px !important;
}

.main_nav_wrapper nav{
    z-index: 10;
    height: 100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
}

/* @media only screen and (max-width: 1024px) {
    .red_title{
        font-size: min(14vw, 60px);
    }
    .nav_btn{
        visibility: visible;
        opacity: 1;
        display: inline !important;
        padding: 0;
    }
    .main_nav_wrapper{
        padding: 0 1rem;
    }
    .main_nav_wrapper nav{
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        height: 100%;
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        transition: 1s;
        transform: translateY(-100vh);
    }
    .responsive_nav{
        transform: none !important;
    }
    .nav_close_btn {
		position: absolute !important;
		top: 1rem;
		right: 1rem;
	}
    .nav_link{
        font-size: 3rem;
    }
    .call-to-action{
        position: static;
    }
  } */

@media only screen and (max-width: 330px) {
    .nav-logo{
        max-width: 200px;
        padding-top: 8px !important;
    }
}