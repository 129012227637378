@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.about-title{
    font-size: 7vw;
    height: 100%;
    width: 20vw;
    color: white;
    background-color: #fbb300;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
}

.about-title-text-wrap{
    display: flex;
    text-align: center !important;
    justify-content: center;
    width: 20vw;
    margin: auto;
}

.rainbow-border {
	position: relative;
	z-index: 0;
	width: calc(100vw - 2em);
	height: calc(100vh - 80px - 2em);
	border-radius: 10px;
	overflow: hidden;
	margin: 1rem;
}

.rainbow-border::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -100%;
    width: 200%;
	height: 300%;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#fbb300, #fbb300);
    animation: rotate 6s linear infinite;
}

.rainbow-border::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
    animation: opacityChange 3s infinite alternate;
}

@keyframes opacityChange {
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}