@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@900&family=Roboto+Slab:wght@900&display=swap');

*{
  font-family: 'Kanit', sans-serif;
  font-family: 'Roboto Slab', serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

body.preload *{
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}